import React from "react"
import { Link } from "gatsby"

import grid from '../../scss/flexboxgrid.module.scss'
import view from './footer.module.scss'
import CookiePolicy from "../cookie/CookiePolicy"

function Footer(props) {
  const { siteNav, dealerInfo, footer,footerClassName } = props
  const renderLinks = () => (
    <div className={view["foot-cont"]}>
      {
        footer.links.map((item, idx) => (
          <Link id='nav_link' key={idx} className={`${view["btn-text"]}`}
                to={item.linkValue}>
            <span>{item.linkName}</span>
          </Link>
        ))
      }
    </div>
  )
  return (
    <footer id="footer" className={view["footer"] + " " + (footerClassName === undefined ? "" : footerClassName)}>
      <div className={`${grid["row"]} ${view["foot-wrap"]}`}>
        <div className={`${grid["col-lg-12"]} ${view["foot-links"]} ${grid["col-md-8"]} ${grid["col-sm-8"]} `}>
          {renderLinks()}
        </div>
        <div className={`${grid["col-lg-12"]}  ${view["foot-links"]} ${grid["col-md-8"]} ${grid["col-sm-8"]}`} style={{ textAlign: "center" }}>
          <CookiePolicy dealerInfo={dealerInfo} CookieLinkText="Manage Cookie Policy" />
          <Link id='nav_link' to={"https://dealermasters.com/"} className={view["subtitle"]}>
            <p>&#169; {new Date().getFullYear()} {dealerInfo.dealerName}</p>
            <p style={{ display: "inline-block" }}>made with</p>
            <span className={view["heart"]} aria-label={"heart"} role={"img"}> &#10084; ️</span>
            <p style={{ display: "inline-block" }}>by dealer masters</p>
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
